import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './bootstrap.min.css';
import './App.css';
import Navbar from "./section/navbar/navbar";
import Hero from "./section/hero/hero";
import Services from "./section/services/services";
import WhyChooseUs from "./section/whychooseus/whychooseus";
import GreatFeatures from "./section/greatfeatures/greatfeatures";
import Conversations from "./section/conversations/conversations";
import MeetCustomers from "./section/meetcustomers/meetcustomers";
import DownloadNow from "./section/downloadnow/downloadnow";
import CutomerRating from "./section/cutomerrating/cutomerrating";
import Form from "./section/form/form";
import Footer from "./section/footer/footer";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsCondition from "./pages/terms-condition";

function App() {
  let name = "SIT"
  return (
    <>
      <Navbar />
      <Routes path="/" exact>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
        <Route path="/terms-condition/*" element={<TermsCondition />} />
        <Route path="#hero" component={Hero} />
        <Route path="#services" component={Services} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
