import React from 'react';
import './terms-condition.css';

const PrivacyPolicy = () => {
    return (
        <div className="container privacy-policy-container">
        <h2>Privacy Policy</h2>
        <p>
            Welcome to Harsafe Meet. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app. Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the app.
        </p>
    
        <h3>1. Information We Collect</h3>
        <p>
            We may collect information about you in the following ways:
        </p>
    
        <h4>1.1 Personal Information</h4>
        <p>
            We may collect the following personal information when you use the App:
        </p>
        <ul>
            <li><strong>Account Information:</strong> Name, email address, phone number, and profile picture.</li>
            <li><strong>Financial Information:</strong> Shared expense details in the Hisaab Book feature.</li>
            <li><strong>Location Data:</strong> Real-time, current, and other shared locations.</li>
        </ul>
    
        <h4>1.2 Non-Personal Information</h4>
        <p>
            We may collect non-personal information such as:
        </p>
        <ul>
            <li><strong>Device Information:</strong> Device model, operating system, and unique device identifiers.</li>
            <li><strong>Usage Data:</strong> Features used, time spent on the App, and crash reports.</li>
            <li><strong>Log Data:</strong> IP address, browser type, and access times.</li>
        </ul>
    
        <h4>1.3 Media and Files</h4>
        <p>
            The App may request access to your device’s camera, microphone, photos, and files for:
        </p>
        <ul>
            <li>Sharing multimedia files (images, videos, and voice messages).</li>
            <li>Creating and sharing Reels.</li>
        </ul>
    
        <h3>2. Use of Your Information</h3>
        <p>
            We may use the information we collect in various ways, including to:
        </p>
        <ul>
            <li>Provide, operate, and maintain our app.</li>
            <li>Improve, personalize, and expand our app.</li>
            <li>Understand and analyze how you use our app.</li>
            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the app.</li>
        </ul>
    
        <h3>3. Disclosure of Your Information</h3>
        <p>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
        </p>
        <ul>
            <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
            <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
        </ul>
    
        <h3>4. Security of Your Information</h3>
        <p>
            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
        </p>
    
        <h3>5. Your Rights</h3>
        <p>
            You have the right to request access to the personal information we hold about you, to ask for corrections, and to request that we delete your personal data. To exercise these rights, please contact us using the information provided below.
        </p>
    
        <h3>6. Changes to This Privacy Policy</h3>
        <p>
            We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by updating the "Effective Date" at the top of this Privacy Policy.
        </p>
    
        <h3>7. Contact Us</h3>
        <p>
            If you have questions or comments about this Privacy Policy, please contact us at:
            <br />
            <strong>Email:</strong>
            <a href="mailto:support@harsafemeet.com" className="contact-link">support@harsafemeet.com</a>
        </p>
    </div>
    
    );
};

export default PrivacyPolicy;
