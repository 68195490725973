import React from 'react'
import { Link } from 'react-router-dom'
import '../footer/footer.css'
import LogoPurple from './../../images/logo-purple.png'

const footer = () => {

    return (

        <footer className="bg-light-grey">
            <div className="container">
                <div className="cta-section">
                    <h2>
                        Ready To Grow Your Business? <br />
                        Start with Harsafe Meet, Become Faster Every Second
                    </h2>
                    <Link to="#" className="btn-primary btn btn-lg">
                        Start Chating Now
                    </Link>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 text-center m-t-70">
                        <div className="foter-medium">
                            <img
                                src={LogoPurple}
                                alt="Harsafe Meet Logo"
                                className="img-responsive foter-logo"
                            />
                            <nav className="footer-nav">
                                <ul className="f-s14 f-w700 grey">
                                    <li><Link className="page-scroll" to="/#home">Home</Link></li>
                                    <li><Link className="page-scroll" to="/#services">Services</Link></li>
                                    <li><Link className="page-scroll" to="/#features">Features</Link></li>
                                    <li><Link className="page-scroll" to="/#download">Download</Link></li>
                                    <li><Link className="page-scroll" to="/#contact">Contact</Link></li>
                                </ul>
                            </nav>
                            <div className="social">
                                <a href="https://www.facebook.com" className="green" aria-label="Facebook">
                                    <i className="fa fa-facebook-square fa-2x m-r-20" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.twitter.com" className="green" aria-label="Twitter">
                                    <i className="fa fa-twitter fa-2x m-r-20" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.linkedin.com" className="green" aria-label="LinkedIn">
                                    <i className="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Harsafe Meet. All Rights Reserved.</p>
                    <div className="footer-bottom-links">
                        <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-condition">Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default footer